import React from 'react'
import Error from 'next/error'
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik'
import Layout from '../components/layout'
import Logo from '../components/logo'
import Header from '../components/cms/header'
import Footer from '../components/cms/footer'
import { getMenu, Menu } from '../utils/menus'
import { NextPage } from 'next'
import { getSetting } from '../utils/settings'
import { getPage, Page } from '../utils/pages'
import { HTMLRenderer } from '@react-page/renderer'
import { login } from '../utils/auth'
import { useFetch, Request, createUrl, ResponseWithError, ResponseWithJWT } from '../utils/fetch'
import FloatingLabel from '../components/form/floatingLabel'
import Strings from '../components/strings'
import { getToken } from '../utils/auth'

type Login = {
  email: string
  password: string
}
interface Props {
  headerMenu: Menu[]
  footerMenu: Menu[]
  token?: string
}

const Login: NextPage<Props> = ({ headerMenu, footerMenu, token }) => {
  if (!headerMenu || !footerMenu) {
    return <Error statusCode={404} />
  }

  const loginForm: Login = {
    email: '',
    password: '',
  }

  const handleSubmit = (loginForm: Login, actions: FormikHelpers<Login>) => {
    const { email, password } = loginForm

    const onSuccess = (response: ResponseWithJWT) => {
      login(response.token)
    }

    const onError = (response: ResponseWithError) => {
      actions.setStatus({ message: response.message })
      actions.setErrors(response.errors as FormikErrors<Login>)
    }

    const onFinally = () => actions.setSubmitting(false)

    const request: Request = {
      url: createUrl(`/v1/auth/login`),
      method: 'POST',
      body: { email, password },
    }

    useFetch(request)
      .then(onSuccess, onError)
      .finally(onFinally)
  }

  return (
    <div className="landingPage">
      <Header headerMenu={headerMenu} token={token} />
      <Layout>
        <div className="login__container h=viewport flex align-center justify-center">
          <div className="login elevated c=text r p=2">
            <span className="px=2 py=1 w=full block mb=2">
              <Logo />
            </span>
            <Formik initialValues={loginForm} onSubmit={handleSubmit}>
              {({ status, isSubmitting, dirty }) => (
                <Form>
                  <FloatingLabel
                    type="text"
                    name="email"
                    placeholder={Strings.de.pages.login.email}
                    autoComplete="username email"
                    required
                  />
                  <FloatingLabel
                    type="password"
                    name="password"
                    placeholder={Strings.de.pages.login.password}
                    autoComplete="current-password"
                    required
                  />

                  {status && status.message && <p className={`c=err p=1`}>{status.message}</p>}

                  <div className="flex justify-center align-center">
                    <button
                      type="submit"
                      className="mt=2 c=prim ml=1 r-circle px=2 py=1 cm--btn-primary"
                      disabled={!dirty || isSubmitting}
                    >
                      {Strings.de.pages.login.login}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="px=2 mt=4 helpText">
              <p>
                Sie haben noch keinen Kundenzugang? Bitte buchen Sie mindestens einen Assistenten und melden Sie sich
                anschließend im Bereich "Kundenzugang erstellen" an.
              </p>
            </div>
          </div>
        </div>
        <style jsx>{`
          .login {
            width: 450px;
            max-width: 100%;
          }
          .helpText {
            font-size: 0.9em;
          }
        `}</style>
      </Layout>
      <Footer headerMenu={headerMenu} footerMenu={footerMenu} />
    </div>
  )
}

Login.getInitialProps = async ({ res, req }) => {
  const handleError = (error: any) => {
    if (error && res) {
      res.statusCode = 404
    }
  }

  const headerMenu = await getMenu('header').catch(handleError)
  const footerMenu = await getMenu('footer').catch(handleError)
  const token = getToken({ req })

  return {
    headerMenu,
    footerMenu,
    token,
  } as Props
}

export default Login
